@import 'helpers'

.outcome-container
  background-color: var(--bgColor-muted)

  // To prevent flickering when changing meeting states due to the actions button icon
  .title
    min-height: 32px

.op-meeting-outcome-notes
  display: grid
  grid-template-columns: auto fit-content(40px)
  grid-template-areas: "title actions" "content ."




