//-- copyright
// OpenProject is an open source project management software.
// Copyright (C) the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See COPYRIGHT and LICENSE files for more details.
//++

.router--work-packages-full-view:not(.router--work-packages-full-create)
  @include extended-content--bottom
  @include extended-content--right

.work-packages--show-view
  display: flex
  flex-direction: column
  height: inherit

.work-packages-full-view
  &--split-container
    display: flex
    flex-shrink: 8
    border-top: 1px solid var(--borderColor-default)
    overflow: visible
    height: 100%
    // Important for Firefox to let 'flex-shrink' work correctly.
    min-height: 0

  &--split-left
    border-right: 1px solid var(--borderColor-default)
    overflow-y: auto
    overflow-x: clip
    flex: 2
    position: relative
    @include styled-scroll-bar

    .work-packages--panel-inner
      padding: 0px 5px 20px 0
      width: 100%

      // These styles were taken over from the details tab styling.
      // Thus the header and the details tab can be aligned on the same line.
      .attributes-group:first-of-type
        margin-top: 0px

        .attributes-group--header-container
          padding-bottom: 2px

          h3.attributes-group--header-text
            line-height: calc(30px)

  &--split-right
    flex-basis: var(--full-view-split-right-width)
    min-width: 550px
    overflow-y: hidden
    overflow-x: auto
    position: relative
    @include styled-scroll-bar

    .work-packages--panel-inner
      display: grid
      grid-template-rows: auto auto 1fr
      height: 100%
      padding: 5px 0 10px 15px

    .tabcontent
      height: 100%
      overflow-y: auto
      overflow-x: hidden
      @include styled-scroll-bar
      padding-right: 8px

    .work-package-details-activities-activity-contents ul.work-package-details-activities-messages
      padding-left: 0

    li .message
      white-space: normal
      overflow-wrap: anywhere
      word-break: normal

    .activity-comment
      margin-top: 15px

    .button.icon-edit.ng-hide
      display: block !important
      visibility: hidden

.nosidebar
  ul.subject-header
    width: 67%

    li
      &.inline-edit
        width: 75%

@media only screen and (max-width: $breakpoint-xl)
  .work-packages--details
    @at-root
      .detailsViewMenuItem
        display: block

@media only screen and (max-width: $breakpoint-xl)
  .work-packages--show-view
    // Important for Safari
    height: initial
  .work-packages-full-view--split-right
    overflow: visible
    flex-basis: initial !important

@media only screen and (max-width: $breakpoint-sm)
  #toolbar
    #toolbar-items
      margin-left: 0

#work-packages-index
  .op-uc-link_permalink
    display: none

.work-packages--show-view
  .subject-header
    .work-packages--subject-element,
    .work-packages--details--subject .inline-edit--field
      font-size: 20px
      font-weight: var(--base-text-weight-bold)

.work-packages--subject-type-row
  display: flex
  position: relative
  line-height: 24px

.work-packages--type-selector:not(.wp-new-top-row--element)
  .inline-edit--display-field
    padding-right: 5px !important
    // Remove left padding from type
    padding-left: 0 !important

  .inline-edit--container.-active
    width: 130px
