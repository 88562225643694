@mixin timeline-label
  display: flex
  align-items: center
  min-width: 20px
  width: max-content
  font-size: 14px
  white-space: nowrap
  vertical-align: middle
  pointer-events: none

@mixin scheduling-icon
  span
    display: flex
    align-items: center
    .display-field--scheduling-icon
      margin-right: 0.25rem

.timeline-element
  // Label style
  .-label-style.not-empty
    background-color: var(--body-background)
    border: 1px solid var(--borderColor-default)
    border-radius: 5px
    font-size: 12px
    padding: 2px 5px
    // Ensure line-height is normal
    line-height: 1

  .labelLeft.not-empty
    @include timeline-label

  .labelHoverLeft.not-empty
    pointer-events: none
    white-space: nowrap

  .labelLeft.not-empty,
  .labelHoverLeft.not-empty
    // Position container left of bar
    position: absolute
    left: 0px
    top: 0px
    // Then translate by its own width + some margin
    transform: translateX(calc(-100% - 15px))
    // Ensure line-height is normal
    line-height: 1
    @include scheduling-icon

  .containerRight
    @include timeline-label
    position: absolute
    top: 0px
    margin: 0
    padding: 0
    left: 100%
    line-height: 1rem

  .labelRight.not-empty
    @include timeline-label
    margin-left: 20px
    @include scheduling-icon

  .labelFarRight
    @include timeline-label
    font-style: italic
    margin-left: 15px

  // label hover right needs different position
  // since it's not part of containerRight
  .labelHoverRight
    pointer-events: none
    display: none
    top: 0
    white-space: nowrap
    // Position container right of bar
    position: absolute
    right: 0px
    // Then translate by its own width + some margin
    transform: translateX(calc(100% + 15px))
    font-size: 12px
    @include scheduling-icon

  &.-editable
    cursor: ew-resize

  .show-on-hover
    display: none

// Hide or show elements on hover
.wp-timeline-cell.row-hovered
  .show-on-hover.not-empty
    display: inline-block

  .hide-on-hover
    display: none


