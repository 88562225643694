@import 'helpers'

.projects-autocomplete-with-search-icon
  ng-select[ng-reflect-multiple='false']
    input
      margin-left: 18px
  ng-select
    .ng-select-container
      @extend .icon-search
      &:before
        @include icon-font-common
        padding-left: 10px
    // Reduce width of search icon from width of ng-container value which is calc(100% - 30px)
    .ng-value-container
      width: calc(100% - 60px)
