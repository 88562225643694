//-- copyright
// OpenProject is an open source project management software.
// Copyright (C) the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See COPYRIGHT and LICENSE files for more details.
//++

#hover-card-overlay
  display: none

  &:has(.op-hover-card)
    display: flex
    flex-direction: column-reverse

@keyframes fade-in-hover-card
  from
    opacity: 0
  to
    opacity: 1

.op-hover-card
  inset: unset // needed to position the popover freely on the page
  border: none // override the default border for popovers
  animation: fade-in-hover-card .25s ease-in
  background-color: var(--body-background)
  width: 500px
  box-shadow: var(--shadow-floating-medium)
  padding: var(--overlay-paddingBlock-normal)
  border-radius: var(--overlay-borderRadius)
  opacity: 0

  &:popover-open
    opacity: 1
